import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";
function HeroVideo({ urlVideo }) {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <div>
      <div className="w-full relative content_video flex justify-center md:mb-10 lg:mb-20">
        <video
          playsInline
          autoPlay
          muted
          loop
          className="w-full md:h-full object-cover after:bg-[#000]"
        >
          <source
            src={urlVideo}
            type="video/mp4"
          />
        </video>
        <div className="flex md:flex-row flex-col md:w-[100%] absolute md:bottom-[250px] md:text-start text-white">
          <div className="w-full md:w-[70%] px-20 md:pt-32 pt-20">
            <h1 className="text-[35px] text-center md:text-start md:text-[2em] lg:text-[3em]">
              {rpdata?.dbSlogan?.[0].slogan}
            </h1>
            <ButtonContent btnStyle="three" />
          </div>
          <div className="w-full md:w-[30%] pb-10 md:pb-0">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/video1-f8800.appspot.com/o/18%20years.png?alt=media&token=3bb66ae3-9a54-456e-839f-b9002659bb20"
              alt="years of experience"
              className="w-full h-full object-cover"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default HeroVideo;
